<template>
  <div class="year-memory-screen">
    <div class="content">
      <swiper
        class="swiper"
        :options="swiperOption"
      >
        <swiper-slide
          v-for="(img, index) in gallery"
          :key="index"
        >
          <img :src="img.path" />

        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <h1 class="title">
        Год памяти и славы
      </h1>
      <h3 class="title-description">
        У подвига поколения Победителей и благодарной памяти их потомков нет и не может быть срока давности, меры, границ
      </h3>
      <a href="#" class="link">
        Подробнее
        <arrows-yellow class="arrow" />
      </a>
      <div class="about-project">
        <h3 class="about-project__title">
          О проекте
        </h3>
        <p class="about-project__text">
          Самоотверженность, стойкость, неподдельная любовь к Родине фронтовиков и тружеников тыла определили исход самой страшной войны в истории человечества и спасли народы планеты от истребления, рабства, уничтожения национальной и культурной идентичности. И сама наша жизнь, жизнь наших детей, внуков, всех, кто ещё только родится на этой земле – прямая заслуга людей, подаривших миру Великую Победу.
        </p>
        <p class="about-project__text">
          Осознавать это, чувствовать сердцем и передавать из поколения в поколение – наш священный нравственный долг. Но это ещё и наша гражданская ответственность за сохранение правды и справедливости, равноправия и гуманизма, за утверждение свободы личности и суверенных прав государств.
        </p>
      </div>
      <div class="content-swiper content-swiper--one">
        <swiper-image
          class="content-swiper__swiper"
          :space-between="30"
          :images="images_1"
        />
        <h2 class="content-swiper__title">
          Великое кино великой страны
        </h2>
        <p class="content-swiper__description">
          Жители всей России смогут увидеть «Великое кино великой страны».
          Зрителям бесплатно покажут ставшей культовой драму Сергея Бондарчука «Судьба человека» и документальную ленту «Простые главные слова» Сергея Майорова.
        </p>
        <detailed-button
          :btn-text="'Подробнее'"
          class="content-swiper__detailed-btn"
        />
      </div>
      <div class="content-info">
        <div class="wrapper">
          <img class="content-info__photo" src="@/assets/img/banners/banner-3.jpg" />
          <div class="content-info__text">
            <h3 class="content-info__text-title">
              Свет мира
            </h3>
            <p class="content-info__text-description">
              В день окончания Второй мировой войны и капитуляции Японии в советско-японской войне, по всей России вечерняя подсветка преобразится в цвета ленты медали СССР «За Победу над Японией» - красный-желтый-белый. Акция «Свет мира» пройдет в рамках Года памяти в честь празднования 75-летней годовщины окончания Второй мировой войны.
            </p>
          </div>
          <detailed-button
            :btn-text="'Подробнее'"
            class="content-info__text-detailed-btn"
          />
        </div>
      </div>
      <div class="content-info content-info--grey">
        <div class="wrapper">
          <img class="content-info__photo" src="@/assets/img/banners/book-banner-1.jpg" />
          <div class="content-info__text">
            <h3 class="content-info__text-title">
              «История, рассказанная народом»: атомщики о войне
            </h3>
            <p class="content-info__text-description">
              Вышла книга серии «История, рассказанная народом», посвящённая 75-летнему юбилею атомной промышленности. На страницах издания работники и ветераны отрасли рассказывают о себе,  своих коллегах в годы Великой Отечественной войны.
            </p>
          </div>
          <detailed-button
            :btn-text="'Подробнее'"
            class="content-info__text-detailed-btn"
          />
        </div>
      </div>
      <div class="content-info">
        <div class="wrapper">
          <img class="content-info__photo" src="@/assets/img/banners/fact-banner-1.jpg" />
          <div class="content-info__text">
            <h3 class="content-info__text-title">
              Исторические квесты
            </h3>
            <p class="content-info__text-description">
              Всероссийский исторический квест «Дальневосточная Победа» проводится в Год памяти и славы и посвящен знаменательному для всего мира событию – окончанию Второй мировой войны. Ключевую роль в этой победе сыграла Советская армия.
            </p>
          </div>
          <detailed-button
            :btn-text="'Подробнее'"
            class="content-info__text-detailed-btn"
          />
        </div>
      </div>
      <div class="content-swiper content-swiper--two">
        <swiper-image
          class="content-swiper__swiper"
          :space-between="30"
          :images="images_2"
        />
        <h2 class="content-swiper__title">
          Выставка «Освобождение. Память общей судьбы»
        </h2>
        <p class="content-swiper__description">
          Среди экспонатов, представленных на выставку Антикварной галереей «Раритет», можно увидеть подборку знаков, посвященных юбилею Победы
          в Великой Отечественной войне, стенды
          с редкими открытками, выпущенными в годы войны для поднятия боевого духа.
        </p>
        <detailed-button
          :btn-text="'Подробнее'"
          class="content-swiper__detailed-btn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowsYellow from "@/components/Icons/ArrowsYellow";
import SwiperImage from "@/components/Parts/SwiperImage";
import DetailedButton from "@/components/Parts/DetailedButton";

export default {
  components: {
    ArrowsYellow,
    SwiperImage,
    DetailedButton
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 37,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        centeredSlides: true,
      },
      gallery: [
        {
          path: require("@/assets/img/spec-project/spec-1.jpg")
        },
        {
          path: require("@/assets/img/spec-project/spec-1.jpg")
        },
      ],
      images_1: [
        {
          path: require("@/assets/img/banners/film-banner-1.jpg")
        },
        {
          path: require("@/assets/img/banners/film-banner-1.jpg")
        }
      ],
      images_2: [
        {
          path: require("@/assets/img/banners/exibition-banner-1.jpg")
        },
        {
          path: require("@/assets/img/banners/exibition-banner-1.jpg")
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.year-memory-screen {
  .content {
    height: 1555px;
    overflow-x: hidden;
    overflow-y: auto;

    .swiper {
      height: 510px;
      margin-top: 70px;

      .swiper-slide {
        width: 884px;
        height: 442px;
        margin-right: 12px;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        background: rgba(#fff, 0.2);
      }

      .swiper-pagination-bullet-active {
        background: #007aff;
      }
    }
  }

  .title {
    margin-top: 66px;
    font-size: 72px;
    font-weight: 500;
    line-height: 80px;
  }

  .title-description {
    margin: 0 124px;
    font-size: 32px;
    font-weight: normal;
    line-height: 46px;
    color: rgba(255, 255, 255, 0.75);
  }

  .about-project {
    padding: 135px 124px 495px 124px;
    background-color: #111;
    text-align: left;

    &__title {
      font-size: 60px;
      font-weight: 500;
      color: #fff;
    }

    &__text {
      padding-top: 48px;
      font-size: 32px;
      color: rgba(#fff, 0.75);
      text-align: left;
    }
  }

  .content-swiper {
    position: relative;
    text-align: left;

    &__swiper {
      .swiper-slide {
        width: 884px;
        height: 442px;
      }
    }

    .swiper-content {
      padding-top: 0;
      background-color: rgba(#fff, 0.0);

      .navigation-panel {
        bottom: 5%;
      }
    }

    &__title {
      padding: 0 124px;
      font-size: 48px;
      font-weight: 500;
    }

    &__description {
      padding: 0 124px;
      font-size: 32px;
      font-weight: normal;
      color: rgba(#fff, 0.75);
    }

    &__detailed-btn {
      padding-top: 44px;
    }

    &--one {
      top: -330px;
    }

    &--two {
      top: -260px;
      height: 980px;
    }
  }

  .content-info {
    position: relative;
    background-color: #111;
    text-align: left;

    .wrapper {
      position: relative;
      top: -250px;
    }

    &__photo {
      position: relative;
      padding: 0 124px;
    }

    &__text {
      position: relative;

      &-title {
        padding: 0 124px;
        padding-top: 68px;
        padding-bottom: 36px;
        font-size: 48px;
        font-weight: 500;
        line-height: 56px;
        opacity: 1;
      }

      &-description {
        padding: 0 124px;
        font-size: 32px;
        font-weight: normal;
        line-height: 46px;
        color: rgba(#fff, 0.75);
      }

      &-detailed-btn {
        position: relative;
        margin-bottom: 136px;
      }
    }

    &--grey {
      background-color: #242423;
    }
  }
}
</style>
